/** @jsxImportSource theme-ui */
import React from "react";
import Layout from "../components/Layout";
import Link from "../components/Link";
import Seo from "../components/Seo";
import FlickeringFlame from "../components/FlickeringFlame";
import { Button, Container } from "theme-ui";
import { Themed } from "@theme-ui/mdx";

const NotFoundPage = () => (
  <Layout>
    <Seo />

    <Container
      sx={{
        textAlign: "center",
        flex: "1 1",
        display: "flex",
        flexFlow: "column wrap",
      }}
    >
      <FlickeringFlame sx={{ maxHeight: "40vh" }} />
      <Themed.h2>Ups! Seite nicht gefunden.</Themed.h2>
      <Themed.h3 className="text-muted">Fehler 404</Themed.h3>
      <Themed.p>
        Sorry, die gesuchte Seite gibt es nicht oder wurde mittlerweile
        entfernt.
        <br />
        Schau dich doch mal auf der Startseite um.
        <br />
        <Button as={Link} to="/" variant="primary">
          Zur Startseite
        </Button>
      </Themed.p>
    </Container>
  </Layout>
);

export default NotFoundPage;
